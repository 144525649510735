// import { useEffect, useState } from "react";
// import logo from './logo.svg';
// import Lyra from "@lyrafinance/lyra-js";
import "@fontsource/inter/index.css";

// interface DiscreteSliderProps {
//   min: number;
//   max: number;
//   step: number;
//   defaultValue?: number;
//   onChange: (value: number) => void;
// }

// const DiscreteSlider: React.FC<DiscreteSliderProps> = ({ min, max, step, defaultValue = min, onChange }) => {
//   const [value, setValue] = useState<number>(defaultValue);

//   const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
//     const newValue = Number(e.target.value);
//     setValue(newValue);
//     onChange(newValue);
//   }

//   return (
//     <input
//       type="range"
//       min={min}
//       max={max}
//       step={step}
//       value={value}
//       onChange={handleOnChange}
//     />
//   );
// };

const App: React.FC = () => {
  // const [isMetamaskInstalled, setIsMetamaskInstalled] = useState<boolean>(false);
  // const [ethereumAccount, setEthereumAccount] = useState<string | null>(null);

  // useEffect(() => {
  //   if((window as any).ethereum){
  //     //check if Metamask wallet is installed
  //     setIsMetamaskInstalled(true);
  //   }
  // },[]);

  // async function connectMetamaskWallet(): Promise<void> {
  //   //to get around type checking
  //   (window as any).ethereum
  //     .request({
  //       method: "eth_requestAccounts",
  //     })
  //     .then((accounts : string[]) => {
  //       setEthereumAccount(accounts[0]);
  //     })
  //     .catch((error: any) => {
  //         alert(`Something went wrong: ${error}`);
  //     });
  // }

  // const handleSliderChange = (value: number) => {
  //   console.log(value); // replace this with your own logic
  // };

  return (
    <div className="App bg-white min-h-screen flex items-center justify-center">
      <header className="text-center">
        <h1 className="text-6xl font-bold">
          <span className="text-pink-500 font-bold">p</span>
          <span className="text-gray-500 font-bold">retty</span>
        </h1>
        <h1 className="text-6xl font-bold text-pink-500">
          options
        </h1>
      </header>
    </div>
  );
}

export default App;
